import type { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import {
  CheckoutData,
  encodeBase64Url,
  IntegrationData,
  NavigateToSectionProps,
} from '@wix/pricing-plans-router-utils';
import type {
  PricingPlansPublicAPI,
  NavigateToPackagePickerOptions,
  NavigateToCheckoutOptions,
} from '@wix/pricing-plans-tpa-api';
import { getPlanPrice } from '@wix/pricing-plans-utils';
import { navigateToHeadlessIfNeeded, NavigationType } from '@wix/wix-to-headless-redirect-client';
import type { ViewerScriptFlowAPI, InitAppForPageFn, TFunction } from '@wix/yoshi-flow-editor';
import { PRICING_PLANS_APP_DEF_ID as appDefinitionId } from './constants';
import type { SubPage } from './types/common';
import { resolveCurrencyLocale } from './utils';
import { getFreeTrialDaysLabel } from './utils/getFreeTrialDaysLabel';
import { getPeriodLabel } from './utils/getPeriodLabel';
import { getPlanDuration } from './utils/getPlanValidityCycle';
import { integrationDataToAppSectionParams } from './utils/integrationData';
import { hasMultiplePages } from './utils/multiple-pages';
import { getSectionId, getSubPagePath } from './utils/navigation';

let flowAPI: ViewerScriptFlowAPI;

export const initAppForPage: InitAppForPageFn = async (initParams, apis, namespaces, platformServices, _flowAPI) => {
  flowAPI = _flowAPI;
};

export const exports = async (): Promise<PricingPlansPublicAPI> => {
  const { relativeUrl } = await flowAPI.wixAPI.site.getSectionUrl({
    sectionId: 'membership_plan_picker_tpa',
    appDefinitionId,
  });

  return {
    getTranslatedPricingDetails(plan: PublicPlan) {
      const t = flowAPI.translations.t as TFunction;
      const { value, currency } = getPlanPrice(plan);
      const locale = resolveCurrencyLocale(flowAPI.wixAPI);
      const formatCurrency = flowAPI.getCurrencyFormatter({ language: locale });
      const formattedPrice = value && currency ? (formatCurrency({ value, currency }) as string) : '';
      return {
        price: formattedPrice,
        cycle: getPeriodLabel(plan.pricing?.subscription, t) ?? '',
        duration: getPlanDuration(plan, t),
        freeTrial: getFreeTrialDaysLabel(plan, t),
      };
    },
    async navigateToPackagePicker(options: NavigateToPackagePickerOptions) {
      const runNativeNavigation = () => {
        flowAPI.wixAPI.location.to!(
          relativeUrl! + '?' + integrationDataToAppSectionParams(buildIntegrationData(options)),
        );
      };
      if (flowAPI.experiments.enabled(TPA_EXPERIMENTS.HEADLESS_SUPPORT)) {
        navigateToHeadlessIfNeeded({
          navParams: {
            logicalName: NavigationType.PAID_PLANS,
            params: {
              planIds: options?.planIds,
              checkoutData: buildIntegrationData(options),
            },
          },
          location: flowAPI.wixAPI.location,
          fallbackNavigation: runNativeNavigation,
        });
      } else {
        runNativeNavigation();
      }
    },
    async navigateToCheckout(options: NavigateToCheckoutOptions) {
      const { planId, biOptions, ...checkout } = options;
      const isMultiPageApp =
        flowAPI.experiments.enabled(TPA_EXPERIMENTS.INSTALL_SPLIT_PAGES) && (await hasMultiplePages(flowAPI.wixAPI));

      if (isMultiPageApp) {
        const page: SubPage = {
          name: 'checkout',
          checkoutData: { integrationData: buildIntegrationData({ planIds: [planId], checkout, biOptions }), planId },
        };
        const sectionId = getSectionId(page.name, { isMultiPageApp });
        const path = getSubPagePath(page, { isMultiPageApp });
        const sectionUrl = await flowAPI.wixAPI.site.getSectionUrl({ appDefinitionId, sectionId });
        const currentUrl = '/' + flowAPI.wixAPI.location.path.join('/');
        const disableScrollToTop = currentUrl.startsWith(sectionUrl.relativeUrl!);
        return flowAPI.wixAPI.location.to!(`${sectionUrl.relativeUrl ?? ''}${path}`, { disableScrollToTop });
      }
      const currentUrl = '/' + flowAPI.wixAPI.location.path.join('/');
      const disableScrollToTop = currentUrl.startsWith(relativeUrl!);

      flowAPI.wixAPI.location.to!(
        relativeUrl! +
          '/payment/' +
          encodeBase64Url({
            integrationData: buildIntegrationData({
              planIds: [planId],
              checkout,
              biOptions,
            }),
            planId,
          } as CheckoutData),
        {
          disableScrollToTop,
        },
      );
    },
  };
};

const buildIntegrationData = (options: NavigateToPackagePickerOptions): IntegrationData => {
  const data: IntegrationData = {
    planIds: options?.planIds,
    title: options?.title,
    subtitle: options?.subtitle,
    minStartDate: options?.checkout?.minStartDate,
    maxStartDate: options?.checkout?.maxStartDate,
  };
  if (options?.checkout?.successStatus?.content) {
    data.verticalStatusContent = {
      titleText: options.checkout.successStatus.content.title,
      buttonText: options.checkout.successStatus.content.cta,
    };
    if (options.checkout.successStatus.content.message) {
      data.verticalStatusContent.contentText = options.checkout.successStatus.content.message;
    }
  }
  if (options?.checkout?.successStatus?.navigation?.type === 'url') {
    data.navigateTo = options.checkout.successStatus.navigation.url;
  } else if (options?.checkout?.successStatus?.navigation?.type === 'page') {
    data.navigateToPageProps = options.checkout.successStatus.navigation.pageId;
  } else if (options?.checkout?.successStatus?.navigation?.type === 'section') {
    data.navigateToSectionProps = options.checkout.successStatus.navigation.options as NavigateToSectionProps;
  }
  if (options?.biOptions) {
    data.biOptions = {
      referralInfo: options.biOptions.referralInfo,
      referralId: options.biOptions.referralId,
    };
  }
  return data;
};
